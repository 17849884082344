<template>
  <div class="home" >
  <div class="hoza-logistics-consultancy">
        <h1 class="hoza-logistics"> STE Hoza Logistique  <br> <span>
          Conseil
        </span> </h1>
      </div>
      <h2>Hoza Logistics Consultancy</h2>
      <div class="experienced_team" >
        <p class="hoza-logistics-p hoza">{{$t('home-page.home.sub-title')}} </p>
      </div>

  </div>
     
  <div class="bod">
   <div class="welcome-container">

     <div class="welcome-text">
       <div class="title-container">
 
         <div class="title welcome-text-title">{{$t('home-page.bod.welcome-text')}}</div>
 
       </div>  
 
       <div class="introduction">
           <div class="intro">
             <p class="intro-text" data-aos="fade-left" data-aos-duration="890">
              {{$t('home-page.bod.introduction')}}
             </p>
             <!-- <div class="intro-CTA-container">
              <router-link to="/contact" class="intro-CTA">{{$t('home-page.bod.contact-us')}}</router-link>
           
                 </div> -->
           
           </div>
           
     
       </div>
 
       
       
      </div>
      <div class="container">
      
       <div class="info" data-aos="fade-left">
         
           <div class="box">
               <div class="icon">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.75 16.5c-1.984 0-3.6-1.616-3.6-3.6s1.616-3.6 3.6-3.6 3.6 1.616 3.6 3.6-1.616 3.6-3.6 3.6z"/></svg>
                   <h3>{{$t('home-page.bod.brief-desc.content[0].question')}}</h3>
               </div>
               <p>{{$t('home-page.bod.brief-desc.content[0].answer')}}</p>
           </div>
           <div class="box">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.75 16.5c-1.984 0-3.6-1.616-3.6-3.6s1.616-3.6 3.6-3.6 3.6 1.616 3.6 3.6-1.616 3.6-3.6 3.6z"/></svg>
                <h3>{{$t('home-page.bod.brief-desc.content[1].question')}}</h3>
            </div>
            <p>{{$t('home-page.bod.brief-desc.content[1].answer')}}</p>
        </div>
       </div>

       <div class="image">
        <img src="../assets/truck.jpg" alt="trucks image">
    </div>
   </div>

 
    
   </div>

 
    

  </div>
  <div class="objectives " >
        

    <div class="objectives-container">
      <h1>{{$t('home-page.objectives.title')}}
      </h1>
      
      <p>{{$t('home-page.objectives.desc')}}</p>

      <div class="cardss" data-aos="fade-left">
          <div class=" card-content">
              <img src="../assets/reduceAccidents.jpg">
              <h2>{{$t('home-page.objectives.objective[0]')}}
              </h2>
   
          </div>
          <div class="card-content">
              <img src="../assets/improve-safety.jpg" >
              <h2>{{$t('home-page.objectives.objective[1]')}}
              </h2>
             
          </div>
          <div class="card-content">
              <img src="../assets/developHummanCapital.jpg" alt="Risk Management Solutions">
              <h2>{{$t('home-page.objectives.objective[2]')}}
              </h2>
              
          </div>
          <div class="card-content">
            <img src="../assets/grow-business.jpg" alt="Risk Management Solutions">
            <h2>{{$t('home-page.objectives.objective[3]')}}
            </h2>
           
        </div>
        
      </div>


  </div>

    
  
  <!-- <div class="after-spending-several-years-i">
    <p class="hoza-logistics-p title">
      
      {{$t('home-page.objectives.title')}}
    </p>
  </div>
  <div class="objec" data-aos="fade-up">
  <div class="group-div" 
>
    <img src="../assets/team-management.png" alt="">
    <p class="improve-the-efficiency-of-oper"
      > {{$t('home-page.objectives.objective[0]')}}</p >
  </div>
  <div class="group-div"
>
  <img src="../assets/worker.png" alt="">
    <p class="improving-company-safety-cultu"
      > {{$t('home-page.objectives.objective[1]')}}</p >
  </div>

  <div class="group-div" 
>
    <img src="../assets/planting.png" alt="">
    <p class="develop-human-capital-to-impro"
      > {{$t('home-page.objectives.objective[2]')}}</p>
  </div>
  <div class="group-div" 
>
    <img src="../assets/sales.png" alt="">
    <p class="grow-their-business-to-meet-in"
      > {{$t('home-page.objectives.objective[3]')}}</p>
  </div>
  
  </div> -->

  
  
  </div>


 <div class="about_container">
      
        <div class="about_ceo" data-aos="fade-left">
      <img src="../assets/ceo-hlc.jpeg" alt="" class="ceo">
      
      <div class="text" data-aos="fade-right">
        <div class="int"> {{$t('home-page.about-container.int')}}</div>
        <div class="hey">
          <h2>{{$t('home-page.about-container.name')}} <span class="ceo_name">Hoza Longfor</span> </h2>
          <p>{{$t('home-page.about-container.subtitle')}}</p>
      
        </div>
        <div >
          <div class="one">{{$t('home-page.about-container.text')}}
            <p><b>{{$t('home-page.about-container.experience[0]')}}</b></p>
          <ul>
            <li>{{$t('home-page.about-container.experience[1]')}}</li>
            <li>{{$t('home-page.about-container.experience[2]')}}</li>
            <li>{{$t('home-page.about-container.experience[3]')}}</li>
            <li>{{$t('home-page.about-container.experience[4]')}}</li>
            <li>{{$t('home-page.about-container.experience[5]')}}</li>

          </ul>
          
          <!-- <p>{{$t('home-page.about-container.final-statement')}}</p> -->
          </div>
          </div>
          <div class="contact-Links">

            <div class="contact">
              
         <router-link to="/contact" class="contacts">{{$t('home-page.about-container.contact-us')}}</router-link>
      
            </div>
            <div class="linked_in"> 
              <a href="https://www.linkedin.com/in/ste-hoza-logistique-conseil-1087a0322" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M17.04 17.043h-2.962v-4.64c0-1.107-.023-2.531-1.544-2.531c-1.544 0-1.78 1.204-1.78 2.449v4.722H7.793V7.5h2.844v1.3h.039c.397-.75 1.364-1.54 2.808-1.54c3.001 0 3.556 1.974 3.556 4.545v5.238ZM4.447 6.194c-.954 0-1.72-.771-1.72-1.72s.767-1.72 1.72-1.72a1.72 1.72 0 0 1 0 3.44Zm1.484 10.85h-2.97V7.5h2.97v9.543ZM18.521 0H1.476C.66 0 0 .645 0 1.44v17.12C0 19.355.66 20 1.476 20h17.042c.815 0 1.482-.644 1.482-1.44V1.44C20 .646 19.333 0 18.518 0h.003Z"/></svg></a> 
            </div>

          </div>
      
      </div>
      
        </div>
        
   
    
      </div>
<section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="fs-6 text-secondary mb-2 text-uppercase text-center"> {{$t('home-page.partners.sm-text')}}</h2>
        <h2 class="mb-4 display-5 text-center">{{$t('home-page.partners.title')}}</h2>
        <p class="fs-5 text-secondary mb-5 text-center">{{$t('home-page.partners.sub-text')}}</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle">
      </div>
    </div>
  </div>

 
      <div class=" " data-aos="fade-right">
        <div class=" px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 ">
          <img 
          :src="imageSrc"  
          @click="enterFullscreen" 
          ref="imageElement"
          alt="cdp group certification"
           class="certification" 
          
          />
        </div>
      </div>
     

   
</section>
<div class="conatiner--linked--in" data-aos="fade-right">
  <div class="linked--in--text">

    <p>{{$t('home-page.linkedInSection.textBlocks[0].text')}}</p>

    <p>{{$t('home-page.linkedInSection.textBlocks[1].text')}}</p>

    <p>{{$t('home-page.linkedInSection.textBlocks[2].text')}}</p>

  </div>
  <div class="contact linked--contacts">
    <router-link to="/contact" class="contacts">{{$t('home-page.about-container.contact-us')}}</router-link>
  </div>
</div>
   <div class="counter ">
   <div class="counter1 track">
   <h3 class="years">0</h3>
   <p>{{$t('home-page.counter.years-of-experience')}}</p>
   </div>
   <div class="counter2 track">
   <h3>+<span class="load">0</span> </h3>
   <p>{{$t('home-page.counter.load-per-year')}}</p>
   </div>
   <div class="counter3 track" >
   <h3>+ <span class="customer">0</span> </h3>
   <p>
    {{$t('home-page.counter.number-Of-Customers')}}</p>
   </div>
   </div>
  <div class="ser">
   <div class="title">{{$t('home-page.services.title')}}</div>
   <p class="desc">Our comprehensive consultancy services address the unique challenges of logistics and transportation companies</p>
   <div class="services" data-aos="fade-left">
   <div class="s">
   
<div class="group-div5" data-aos="fade-left">
        
        <img
          class="rectangle-icon3"
          alt=""
          src="../assets/rectangle-14@2x.png"
        /><p class="freight-shipping-b sercive-title">{{$t('home-page.services.service-title[0]')}}</p>
      </div>
      <div class="group-div6" data-aos="fade-left">
        
        <img
          class="rectangle-icon3"
          alt=""
          src="../assets/rectangle-15@2x.png"
        /><p class="fleet-management-b sercive-title">{{$t('home-page.services.service-title[1]')}}</p>
      </div>
      <div class="group-div7" data-aos="fade-left">
        
        <img
          class="rectangle-icon3"
          alt=""
          src="../assets/rectangle-16@2x.png"
        /><p class="safety-health-and-environment sercive-title"
          >{{$t('home-page.services.service-title[2]')}}</p
        >
      </div>

      <div class="group-div7" data-aos="fade-left">
        
        <img
          class="rectangle-icon3"
          alt=""
          src="../assets/logisticsTraining.webp"
        /><p class="safety-health-and-environment sercive-title"
          >{{$t('home-page.services.service-title[3]')}}</p
        >
      </div>
   </div>
   <div class="link">
   <router-link to="/services" class="links">{{$t('home-page.services.see-more')}}</router-link>
   <router-link to="/training" class="links">{{$t('home-page.services.training')}}</router-link>

   </div>
   </div>

  
  </div>
 
   <div class="targeted_customers">
   <div class="title">{{$t('home-page.Target.title')}}</div>
   <div class="targetss">
    <div class="targets-sub">
      <div class="group-div8 targets" data-aos="zoom-in-up">
        
        <p class="intercity-bus-companies  tar" >{{$t('home-page.Target.targets[0]')}}</p>
      </div>
      <div class="group-div9 targets" data-aos="zoom-in-up">
        
          <p class="loads-per  tar">{{$t('home-page.Target.targets[1]')}}</p>
        
      </div>
      <div class="group-div10 targets" data-aos="zoom-in-up">
     
        <p class="humanitarian-organizations-tra  tar">
          {{$t('home-page.Target.targets[2]')}}
        </p>
      </div>
    </div>
    <div class="targets-sub">
      <div class="group-div11 targets" data-aos="zoom-in-up">
       
        <p class="road-transport-company-by-truc  tar">
          {{$t('home-page.Target.targets[3]')}}
        </p>
      </div>
      <div class="group-div12 targets " data-aos="zoom-in-up">
      
        <p class="multinationals-with-large-vehi tar">
          {{$t('home-page.Target.targets[4]')}}
        </p>
      </div>
      <div class="group-div13 targets" data-aos="zoom-in-up">
        
        <p class="utility-companies-with-operati tar">
          {{$t('home-page.Target.targets[5]')}}
        </p>

   
   
   </div>
    </div>
   
   
   
   </div>
   </div>
 
   <div class="expertise">
     <div class="title">{{$t('home-page.area-expertise.title')}}</div>
     <div class="ex">
      <div class="heavy-vehicle-operations" data-aos="fade-left">
      <img src="../assets/vehicle.png" alt="">
      <p>
        {{$t('home-page.area-expertise.expertise[0]')}}
      </p> </div>
      <div class="training-and-development-of-lo" data-aos="fade-down">
      <img src="../assets/education.png" alt="">
      
       <p>
      
        {{$t('home-page.area-expertise.expertise[1]')}}

        
      </p>
      </div>
      <div class="storage-and-transportation" data-aos="fade-right">
      <img src="../assets/warehouse.png" alt="">
      <p> {{$t('home-page.area-expertise.expertise[2]')}}</p>
      </div>
      
     
     </div>
      <div class="link">
   <router-link to="/expertise" class="links last-link"> {{$t('home-page.area-expertise.see-more')}}</router-link>
   </div>
   
   </div>
     

</template>

<script>
// @ is an alias to /src
import { ref,onMounted, onBeforeUnmount } from 'vue';

import AOS from "aos";
import anime from 'animejs'


export default {
  name: 'HomeView',
  components: {
  },
  methods:{
    onEnt(){
      

    }
  },
  setup(){
   let years=document.getElementsByClassName(".years")
   let customers=document.getElementsByClassName(".customers")
   let load=document.getElementsByClassName(".load")

   let seekProgressEl = document.querySelector(".counter");

   const imageSrc = ref(require('@/assets/certification.webp')); // Use require for dynamic import
    const imageElement = ref(null);

    const enterFullscreen = () => {
      const img = imageElement.value;
      
      if (img.requestFullscreen) {
        img.requestFullscreen();
      } else if (img.mozRequestFullScreen) { // Firefox
        img.mozRequestFullScreen();
      } else if (img.webkitRequestFullscreen) { // Chrome, Safari and Opera
        img.webkitRequestFullscreen();
      } else if (img.msRequestFullscreen) { // IE/Edge
        img.msRequestFullscreen();
      }
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        exitFullscreen();
      }
    };

  onMounted(() => {
    AOS.init(
     
    );
    let animation=anime(
      {
        targets:".years",
        innerHTML:[0 , 23],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false,
     loop:false
      },
       
     
      
      )
      let load=anime({
 
        targets:".customer",
        innerHTML:[0 , 50],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false
   
      
      })
      let customer=anime({
   
        targets:".load",
        innerHTML:[0 ,30000],
        round: 1,
     easing: 'easeInOutExpo',
     duration:2000,
     autoplay:false
   
      
      })
      
      document.addEventListener('keydown', handleKeyDown);
      function scroll() {
       let page=window.pageYOffset
        if(page>=1000){

          animation.play()
          load.play()
          customer.play()
          
        }

};
        window.onscroll =scroll 

        
  
})
onBeforeUnmount(() => {
      document.removeEventListener('keydown', handleKeyDown);
    });

return{
  years,customers,load,imageSrc,
      imageElement,
      enterFullscreen
}
  },
  
}
</script>

<style scoped>


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 

@import url('https://fonts.googleapis.com/css2?family=Parisienne&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Elsie:wght@400;900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


body {
  font-family: "poppins";
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.cls-1{fill:#00b5f0;}.cls-2{fill:#2484c6;}.cls-3{fill:#0052a4;}
section{
  height:100vh;
}
.welcome-container>.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  gap: 30px;
  width:70vw;
  height:40vh;
}

.certification{
  width: 60%;
  height: auto;
  object-fit: cover;
}
section{
  height:170vh; 
}

.image {
  width: 500px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
}
.conatiner--linked--in{
  height:80vh;
  max-height:auto;
  width:70vw;
  margin:auto;
  padding:30px;
}
.linked--in--text{
  line-height:1.8rem;
  font-family: "poppins";
  font-weight: 500;
  font-size:1.2rem;
  overflow-x:hidden;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.box {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.box h3 {
  margin-top: 0;
  font-size: 2rem;
}

.box p {
  margin-bottom: 0;
  font-size: 1rem;
  color: #0f0f0f;
}

.icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon svg {
  width: 24px;
  height: 24px;
  fill: #333;
}

.vision {
  color: white;
}
.ceo_name{
  font-family: "Elsie", serif;
  font-weight: 400;
  font-style: normal;
  font-size:60px;
}

@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  .image {
      width: 100%;
      height: auto;
  }

  .conatiner--linked--in{
    max-height:auto;
    width:100vw;
    margin:auto;
    padding:35px;
  }
  .linked--in--text{
    line-height:1.8rem;
    font-family: "poppins";
    font-weight: 500;
    font-size:1.2rem;
  }

}

.objectives-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  height:120vh;
  margin-bottom:600px;
}

.objectives-container h1 {
  font-family: "poppins";
  letter-spacing: 1px;
  text-transform: uppercase;
  color:black;
  margin-bottom: 10px;
}

.objectives-container p {
  text-align: center;
  max-width: 600px;
  margin: 20px auto;
  font-size: 18px;
}

.objectives-container .cardss {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
  flex-wrap: wrap;
}

.objectives-container .card-content {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.objectives-container  .card-content img {
  width: 150px;
  height: 150px;
  margin: 20px auto;
}

.objectives-container .card-content h2 {
  font-weight: 300;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 10px;
}




.one{
  text-align: left;
  font-size:.8.5rem;
  line-height: 2rem;
  font-family: 'Montserrat', sans-serif;
  margin-left:-10px;
  margin-top:-25px;
  padding: 18px;
  color: #ffffff;
}
  .welcome-container{
  height: 1300px;

  width:100vw;
  position: relative;
  top:-85px;
}
.welcome-text{
  width:95%;
  margin:auto;
  display:flex;
  flex-direction: row;
  max-height: 65vh;
  align-items:center;
  justify-content:center;
  padding:30px;
  margin-bottom:90px;
}
.bod{
height: 90vw;
display: flex;
flex-direction:column;
margin-top:90px;
}
.contacts{
color:rgba(10, 45, 97, 0.829);
font-weight: 600;
font-size: 19px;
text-decoration: none;
text-transform: uppercase;

transition: .3s all ease-in-out;
}

.linkedin{
  width:20px;
  height:20px;

  }
  .linked_in{
    transition:.5s all ease-in-out;
   
  }
  .linked_in:hover{
  color: #fff;
  }
.contact{
background: #fff;
width: 200px;
padding: 15px;
font-weight:400;
transition: .3s all ease-in-out;
margin-top:10px;

}
.linked--contacts{
background: rgba(10, 45, 97, 0.829);

  margin:auto;
  color:#fff;
}
.linked--contacts>a{
  color:#fff;


}
.contact:hover{
background: rgba(255, 255, 255, 0.842);
border-color: none;
}
.contact:hover>.contacts{
color:black;
}
.contact-Links{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:30px;
}

.about_container{
/**background: url("../assets/circle-scatter-haikei\ \(1\).svg") no-repeat center;**/
background-size: cover;
height: 120vh;
width:100%;
margin-bottom:10px;
margin-top:120px;
}
.about_ceo{
width: 100vw;
height: 80vh;
display: flex;
align-items: center;
justify-content: center;
gap:30px;
}
.ceo{
width: 30vw;
height:81vh;
}
.text{
width: 50vw;

background: rgba(2, 23, 54, 0.829);
padding:10px;
height:auto;
color:white;


}

.int{
  font-family:"Montserrat";
text-align: left;
font-size: 22px;
text-transform: capitalize;
font-weight: 600;
}
.hey>h2{
text-align: left;
text-transform: capitalize;
font-size:45px;

}

.expertise{
  display:flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height: 100vh;
  width: 100vw;
  background:url("../assets/group-trucks-parked-line-truck-stop.webp") center center;
background-size: cover;
}

.ex{
display: flex;
align-items: center;
justify-content: center;
gap:30px;
width: 100%;
height:60vh;
}
.ex>*{
background: #ffffff77;
color:rgb(27, 27, 27);
width: 250px;
height: 250px;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
border-radius:5px;

display:flex;
align-items: center;
justify-content:center;
transition: .3s all ease-in-out;
padding:10px;

}


.ex>div>p{
  font-size: 18px;
  font-weight: 400;
  font-family: "roboto";
  text-align: center;
  margin: auto;
  text-transform: capitalize;
  transition: .3s all ease-in-out;

}
.ex>div:hover{
  background:rgba(255, 255, 255, 0.904);
box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);

}
.ex>div:hover>p{
  font-weight: 600;
}

.targetss{
  
display: flex;
gap:20px;
flex-direction: column;
align-items: center;
justify-content: center;

}

.tar{
color:rgb(20, 20, 20);
text-align: center;
font-weight:400;
font-size:20px;
text-align: left;
align-self: center;
white-space: pre-line;
border-radius: 5px;
font-family:"poppins", sans-serif;
}
.targets-sub{
display: flex;
gap:20px;
align-items: center;
justify-content:center;
}
.link{
  display:flex;
  gap :30px;
}
.links{
margin-top: 40px;
text-transform: capitalize;
text-decoration: none;
color: white;
width:250px;
font-size:20px;
font-weight: 600;
/**background-color: #fcaa11;**/
background: rgba(10, 45, 97, 0.89);
padding: 20px;
transition: .3s all ease-in-out;
}
.sercive-title{
font-size: 17px;
font-weight: 400;
}

.links:hover{
color: rgba(10, 45, 97, 0.829);
background: none;
border:1px solid rgba(10, 45, 97, 0.829);

}
.last-link:hover{
  color: white;
background: rgba(10, 45, 97, 0.829);
border:1px solid rgba(10, 45, 97, 0.829);
}
.training-link{
  color:black;
  padding:15px;
  position:relative;
  top:50px;
  
}
body {
  margin: 0;
  line-height: normal;
  overflow-x:hidden;
  
}

.home{
height: 100vh;
width:100vw;
background: url("../assets/rectangle-2@2x.webp");
background-attachment: fixed;
background-position: center center;
  background-size: cover;
object-fit: contain;
overflow-x: hidden;
display: flex;
align-items:flex-start;
justify-content: center;
flex-direction: column;
gap:10px;

margin-bottom:250px;

}
.home>h2{
  color:white;
}
.home>*{
margin-left: 50px;
}




.intro-CTA-container{
  background:rgb(5, 51, 138);
  color:#fff;
  padding: 10px;
  text-align: center;
  width: 200px;
}.intro-CTA{
  color:#fff;
  text-decoration: none;
  font-weight:600;
}


.introduction{
height:auto;
min-width: 500px;
max-width: 800px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
position:relative;
top:5px;
margin-top: 10px;
}
.title-container{
  padding:10px;
}
.logistics-is-basically-defined {
  
  line-height: 199.5%;
  text-transform: capitalize;
  text-align: left;
 
  
}
h1{
color:white;
font-size:50px;
width:80%;
height: 100%;
letter-spacing: 2rem;
}
.intro-text{
  position: relative;
  letter-spacing: 0.01em;
text-transform: capitalize;
  font-size:1.25rem;
  font-weight:500;
  font-family: 'Inter', "poppins";
 
  text-align: left;
}
.ser{
 /** background: url("../assets/blob-scene-haikei.svg");**/
  background-repeat:no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  padding: 10px;
  height:130vh;

}

.ser .desc{
  font-size:19px;
  width: 50%;
}

.rectangle-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 1132px;
  object-fit: cover;
}
.hoza-logistics-p {
  margin-block-start: 0;
  margin-block-end: 13px;
  font-size:18px;
  
}
.consultancy-p {
  margin: 0;
}
.experienced_team{

  letter-spacing: 0.01em;
  line-height: 168%;
  text-transform: uppercase;
  font-weight: 800;
  color: white;
  text-align: left;
  display: inline-block;
  width: 764px;
  height: 43px;
}
.hoza-logistics-consultancy {
  font-size: 60px;
  letter-spacing: 0.01em;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 800;
  color: #fdfcfc;
  text-align: left;
  display: inline-block;
  position:relative;
  top:-15px;
  width: 900px;
 
  height: 300px;
}
.hoza-logistics-consultancy::after {
content: " ";
position: absolute;
background:#fcaa11;
width:70%;
height: 4px;
}

.hoza-logistics-consultancy>h1{
font-size:80px;
font-family: 'Montserrat', sans-serif;
letter-spacing: 3px;
line-height: 1;
  height: 80%;
}

.intro{
display: flex;
flex-direction:column;
justify-content:center;
align-items: center;
margin:auto;
width:50%;


}
.an-experienced-team-of-logisti {
  position: absolute;
  top: 465px;
  left: 57px;
  letter-spacing: 0.01em;
  line-height: 168%;
  text-transform: capitalize;
  font-weight: 800;
  color: white;
  text-align: left;
  display: inline-block;
  width: 764px;
  height: 43px;
}.icon1{
position:relative;
width: 300px;
height:300px
}
.objectives{
display:flex;
align-items: center;
justify-content: center;
height:60vh;
margin-top: 80px;
position:relative;
flex-direction: column;
padding:20px;

}
.objec{
 display: grid;
 grid-template-columns: repeat(2,300px);
grid-template-rows: repeat(2,250px);
grid-column-gap: 25px;
grid-row-gap: 80px;
margin-top: 50px;
}
.ex>*>p{
text-align: center;
align-self: center;
}
.key{
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
}

.objective{
display: flex;
gap:20px;
align-items: center;
justify-content: center;
margin-top: 15px;

}
.group-div{

padding: 10px;
background-color:rgb(255, 255, 255);
border-radius: 3px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
border:.5px solid #d8d8d8d0;
transition:.3s all ease-in-out;
position:relative;

}
.group-div:hover{
box-shadow: 0 5px 10px rgba(82, 78, 78, 0.432);
}

.group-div>p{
text-align: center;
font-size: 18px;
line-height: 1.7;
font-weight: 500;
color:#161616;
text-transform: capitalize;
}
.group-div>img{
width: 80px;
height:80px;
position:absolute;
top:-50px;
}
.counter{
width: 100vw;
height: 38vh;
background:url('../assets/rectangle-8@2x.webp') no-repeat;

background-position: center center;
  background-size: cover;
object-fit: contain;
 


  display:flex;
  align-items: center;
  justify-content:space-around;
color:white;
font-size:25px;
}
.services{
height: 70vh;
color:black;
display:flex;
flex-direction: column;
align-items: center;
justify-content: start;
}
.track{

text-transform: capitalize;
display: flex;

flex-direction: column;
align-items: center;
justify-content: space-around;

}
.track>p{
  font-size:18px;
  font-weight:700;
  font-family: "poppins",sans-serif;

}
.track>h3{
  font-family:'poppins';
  font-weight:600;
}
.service{
color:black;
position:relative;
}
.service::before{
content:' ';
position:absolute;
background-color:#fcaa11 ;
height:5px;
margin:auto;
width:30%;
bottom: 10px;
left:280px;
}
.s{
display:flex;
justify-content: space-between;
gap:50px;

}
.s>*{
background: rgba(255, 255, 255, 0.658);
max-width: 290px;
width:240px;
height: 380px;
border-radius: 6px;
transition:.3s all ease-in-out;
}
.s>*:hover{
box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.35);

}
.rectangle-icon3{
width: 240px;
height: 200px;


}
.sercive-title{
font-size: 16px;
font-weight:400;
margin-top: 45px;

}
.title{
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
font-weight: 600;
position: relative;
margin:0px 0 30px 0;
}
.welcome-text-title{
  
  width:80%;
  padding: 10px;
  font-size:60px;
  line-height:1.5em;
  text-align:left;
  font-weight: 800;
  font-family:'poppins', Times, serif;

}
.title::after{
position:absolute;
content:" ";
background:#fcaa11;
width:40%;
bottom: 0;
left:30%;
height:2px;

}
.hoza{
font-weight: 500;
font-size: 18px;
text-transform: capitalize;
}
.small_bio{
  display: flex;
  height:90vh;
  width:100vw;
}
.image_ceo{
  background: url("../assets/ceo.png");
  width: 50%;
  background-position: center center;
  background-size: cover;
}
.bio{
  background: rgb(19, 29, 77);
  width: 50%;
}
.bio>div>p{
  color:white;
  font-size:16px;
  text-align: left;
}
.targeted_customers{
  height: 100vh;
  }
  .targets{

    background:rgba(207, 207, 207, 0.548);
    width:300px;
    height:250px;
    padding:15px;
    margin-top:20px;
    display: flex;
    align-items: center;
    border: .5px solid rgba(255, 255, 255, 0.808);
    justify-content: center;
    }
 

  @media screen and (min-width: 768px) and (max-width: 1023px) {
      .welcome-text-title{
  
        width:60%;
        padding: 10px;
        font-size:40px;
        line-height:1.1em;
        text-align:left;
        font-weight: 800;
        font-family:'Times New Roman', Times, serif;
      
      }
 .welcome-container{
  width:100vw;
  height: 900px;
  position: relative;
  top:-85px;
}
.welcome-text{
  width:100%;
  margin:auto;
  display:flex;
  flex-direction: row;
  height: 70vh;
  align-items:center;
  justify-content:center;
  padding:20px
}
  .bod{
    height: 190vh;
    display: flex;
    flex-direction:column;
    width:100%;
    }
    .box {
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      width:70vw;
    }
    
    .box h3 {
      margin-top: 0;
      font-size: 1rem;
    }
    
    .box p {
      margin-bottom: 0;
      font-size: .75rem;
      color: #666;
    }
  .hoza-logistics-consultancy {
    font-size: 50px;
    letter-spacing: 0.01em;
    line-height: 58%;
    text-transform: uppercase;
    font-weight: 800;
    color: #fdfcfc;
    text-align: left;
    display: inline-block;
    width: 600px;
    height: 250px;
  }
  .hoza-logistics-consultancy::after {
  content: " ";
  position: absolute;
  background:#fcaa11;
  width:30%;
  height: 4px;
  }

  
.intro{
display: flex;
flex-direction:row;
justify-content:center;
align-items: center;
margin:auto;
width:80%;

}
.intro-text{
text-transform: capitalize;
line-height: 1.9;
  font-size:16.5px;
  font-weight:500;
  font-family: 'poppins', sans-serif;

  text-align: left;
}

  .introduction{
    height:60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position:relative;
    line-height: 2;
    top:5px;
    margin-top: 10px;
    }

.s{
flex-direction: column;

}
.s>*{
width: 390px;
height: 350px;
background: #FFFFFF;
box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
transition:.4s all ease-in-out;
}
.s>*:hover{
box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.35);

}
.rectangle-icon3{
width: 290px;
height: 150px

}
.s>div>p{
font-size: 20px;
font-weight:500;
margin-top: 45px;

}
.services{
height: 1500px;
color:black;
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;}
.icon1{
position:relative;
width: 250px;
height:250px
}
.logistics-is-basically-defined {
  position: relative;
  letter-spacing: 0.001em;

  
}

.hoza-logistics-p {
  margin-block-start: 0;
  margin-block-end: 13px;
  font-size: 16px;
  width: 100%;
  margin: auto;
}
.image img{
 display:none;
}
.group-div{

padding: 10px;
}
.group-div>p{
text-align: center;
font-size: 14px;
line-height: 1.8;
font-weight: 500;
color:rgb(39, 40, 43);
text-transform: capitalize;
}
.group-div>img{
width: 50px;
height:50px;
position:absolute;
top:-30px;
}
.objectives{
  display:flex;
  align-items: center;
  height:280vh;
 top:-150px;
  position:relative;
  flex-direction: column;
  
  }
  .objectives-container {
   
    padding: 50px;
    height:120vh;
  }
  
  .objectives-container h1 {
    font-family: "poppins";
    letter-spacing: 1px;
    text-transform: uppercase;
    color:black;
    margin-bottom: 10px;
  }
  
  .objectives-container p {
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    font-size: 18px;
  }
  
  .objectives-container .cardss {
  
    gap:20px;
  
  }
  
  .objectives-container  .card-content {
    background-color: #f2f2f2;
 
  }
  
  .objectives-container  .card-content img {
    width: 250px;
    height: 250px;
  }
  
  .objectives-container  .card-content h2 {
    margin-bottom: 10px;
    font-weight: 400;

  }

  section{
    height:100vh;
  }
  

.ex{
flex-direction: column;
}
.counter{
width: 100vw;
height: 40vh;

background-position: center center;
  background-size: cover;
object-fit: contain;
  margin-top:60px;

  display:flex;
  align-items: center;
  justify-content:space-around;
color:white;
font-size:12px;
}
.track>h3{

text-transform: capitalize;
display: flex;
font-size: 30px;
flex-direction: row;
align-items: center;
justify-content: space-around;

}
.links{
margin-top: 90px;
text-transform: uppercase;
text-decoration: none;
color: white;
width:200px;

font-size:20px;
font-weight: 500;
padding: 10px;
transition: .5s all ease-in-out;
display:block;
}
.links:hover{
background: #fcaa11;
box-shadow: 0px 4px 20px 6px rgba(34, 176, 185, 0.3);
}

.targets{

  background:rgba(255, 255, 255, 0.808);
  width:220px;
  height:200px;
  padding:10px;
  margin-top:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

.tar{

color:white;
text-align: left;
white-space: pre-line;
margin-left: 20px;
font-size: 13px;
font-weight: 300;
}
.targeted_customers{
height: 140vh;
padding:30px;

}

.expertise{
  display:flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height: 160vh;
  width: 100vw;
background-size: cover;
}
.ex{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:30px;
  width: 100%;
  height:100vh;
  }
  .ex>*{
  background: #ffffff77;
  color:rgb(27, 27, 27);
  width: 280px;
  height: 380px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius:5px;
  
  display:flex;
  align-items: center;
  justify-content:center;
  transition: .3s all ease-in-out;
  padding:10px;
  
  }
  
  
  .ex>div>p{
    font-size: 17px;
    font-weight: 300;
    font-family: "roboto";
    text-align: center;
    margin: auto;
    text-transform: capitalize;
    transition: .3s all ease-in-out;
  
  }

.title{
  font-size:40px;
  text-transform: uppercase;
  font-weight: 800;
  position: relative;
  margin-bottom: 30px;
  }
  .title::after{
  position:absolute;
  content:" ";
  background:#fcaa11;
  width:70%;
  bottom: 0;
  left:20%;
  height:2px;
  
  }

.about_container{
/**background: url("../assets/circle-scatter-haikei\ \(1\).svg") no-repeat center;**/
background-size: cover;
width:100vw;
margin-bottom:10px;
}
  .about_ceo{
    width: 100vw;
    height: 190vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding:10px;
    }
    .ceo{
    width: 12vw;
    height:87vh;
    }
    
    .text>div>p{
      font-family: 'Titillium Web', sans-serif;
    
    text-align:left;
    font-weight: 400;
    font-size: 19px;
    color:rgba(240, 240, 240, 0.89);

    line-height:1.7;
    }
   
     .ceo{
    display:none;}
    .one{
      width:90%;
      margin:auto;
  text-align: left;
  font-size:17px;
  line-height: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  margin-left:20px;
  margin-top:-25px;
}

    .text{
       width: 100%;
    padding:20px;
    height:auto;
      }
    
      .int{
      text-align: left;
      font-size: 12px;
      text-transform: capitalize;
      font-weight: 500;
      color:rgba(240, 240, 240, 0.589);
      }
      .hey>h2{
      text-align: left;
      text-transform: capitalize;
      font-size: 35px;
  margin-left:20px;

      }
      .ser{
        background: url("../assets/blob-scene-haikei.svg");
        background-repeat:no-repeat;
        background-size: cover;
        width: 100%;
        padding: 10px;
        height:290vh;
        position: relative;
        
      
      }
      .ser>.desc{
        height:200px;
        color:#fff;
        font-size: 22px;
      }
      .contact{
        background: #fff;
        width: 200px;
        padding: 15px;
        font-weight:400;
        transition: .3s all ease-in-out;
        margin-left:20px;

        
        }
        .linked--contacts{
          background: rgba(10, 45, 97, 0.829);
          background:red;
            margin:auto;
            color:#fff;
          }
          .linked--contacts>a{
            color:#fff;
          
          
          }
      .targets-sub{
        display: flex;
        gap:10px;
      
        }
        .tar{
          color:rgb(14, 14, 14);
          text-align: left;
          font-weight:300;
          font-size:15px;
          align-self: center;
          white-space: pre-line;
          border-radius: 5px;
          width:250px;
          height:80px;
          }
          .targets-sub{
          display: flex;
          gap:10px;
          align-items: center;
          justify-content:center;
          }
          .objec{
            display: grid;
            grid-template-columns: repeat(2,200px);
           grid-template-rows: repeat(2,200px);
           grid-column-gap: 20px;
           grid-row-gap: 10px;
           margin-top: 50px;
           }
          }    
  @media screen and (min-width: 320px) and (max-width: 767px)  { 
            .expertise{
              display:flex;
              align-items:center;
              justify-content: center;
              flex-direction: column;
              width:100%;
              height: 150vh;
              width: 100vw;
            background-size: cover;
            }
          .links{
          display:block;
          }
          .ser{
            background: url("../assets/blob-scene-haikei.svg");
            background-repeat:no-repeat;
            background-size: cover;
            width: 100%;
            padding: 10px;
            height:200vh;
            position: relative;
            display:block;
            margin:auto;
            
      
        }
          .hoza-logistics-consultancy {
            letter-spacing: 0.01em;
            position:relative;
            top:-90px;
            text-transform: uppercase;
            font-weight: 700;
            color: #fdfcfc;
            text-align: left;
            display: inline-block;
            width: 500px;
            height: 190px;
          }
          .hoza-logistics-consultancy::after {
          content: " ";
          position: absolute;
          background:#fcaa11;
          width:60%;
          height: 4px;
          margin-top:20px;
          }
         .hoza-logistics-consultancy>h1{
            font-size:55px;
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 3px;
            line-height: 1;
              height: 60%;
        }
          
          .hoza-logistics-p {
            margin-block-start: 0;
            margin-block-end: 13px;
            font-size: 15px;
           
          }
          .consultancy-p {
            margin: 0;
          }
          .experienced_team{
          
            letter-spacing: 0.01em;
            line-height: 2%;
            text-transform: uppercase;
            font-weight: 800;
            color: white;
            text-align: left;
            display: inline-block;
            width: 80%;
            }
            
            .home{
          height: 100vh;
          gap:0px;
          width:100vw;
          }
          .home>*{
            position:relative;
            top:140px;
          margin-left:20px;
          }
          .welcome-text{
            width:100%;
            display:flex;
            flex-direction: column;
            height: 70vh;
            align-items:center;
            justify-content:center;
            padding:20px;
            position:relative;
            margin:1px;
            margin-bottom: 390px;
          top:0px;
          }
          
        
          
          .welcome-container{
            top:0px;
            width:100vw; 
            position: relative;
            height: 50%;
            justify-content: space-between;
          }
            .intro-text{
      text-transform: capitalize;
      line-height: 2.6;
        font-size:16.5px;
        font-weight:500;
        font-family: 'poppins', sans-serif;

        text-align: left;
}                                                  
          .bod{
            height: 130vh;
            display: flex;
            flex-direction:column;
            align-items: flex-start;
            justify-content: space-between;
            width:100%;
            position:relative;

            }
          .title{
          font-size:30px;
          text-transform: uppercase;
          font-weight: 800;
          position: relative;
          margin-bottom: 30px;
          margin-top: 60px;

              }
        .about_container{
    /**background: url("../assets/circle-scatter-haikei\ \(1\).svg") no-repeat center;**/
        background-size: cover;
        height:auto;
        width:100vw;
        margin-bottom:10px;
    }
         .welcome-text-title{
  
        width:60%;
        padding: 10px;
        font-size:35px;
        line-height:1.1em;
        text-align:left;
        font-weight: 800;
        font-family:'poppins', Times, serif;
      
      }
          
          .title::after{
            
          position:absolute;
          content:" ";
          background:#fcaa11;
          width:80%;
          bottom: 0;
          left:10%;
          height:2px;
          
          }
          .icon1{
          position:relative;
          width: 150px;
          height:150px;
          display:none; 
          }
          .targets{
          
            background:#9c9b9b65;
            width:250px;
            height:200px;
            padding:10px;
            margin-top:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            }
          
          .logistics-is-basically-defined {
            position: relative;
            letter-spacing: 0.001em;
            width:90%;
            padding: 40px;
            margin-top:35px;
            left:-20px;
          }
          
          .intro{
          display: flex;
          flex-direction:column;
          justify-content:center;
          align-items: center;
          width:100vw;
            padding:20px;
          
          }
          .introduction{
            height:40vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position:relative;
          
            margin-top: 60px;
          }

          .image img{
            display:none;
           }

            .group-div{
          
              background-color:transparent;
              border-radius: 3px;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row;
              border:none;
              transition:.3s all ease-in-out;
              position:relative;
              gap:10px;
              }
            
             
              
              .group-div>p{
              text-align: left;
              font-size: 16.5px;
              line-height: 1.5;
              font-weight: 500;
              color:#161616;
              text-transform: capitalize;
              width: 300px;
              }
              .group-div>img{
              width: 50px;
              height:50px;
              position:relative;
              top:0;
                   }


                   .objectives{
                    display:flex;
                    align-items: center;
                    height:280vh;
                   top:50px;
                    position:relative;
                    flex-direction: column;
                    
                    }
                    .objectives-container {
                     margin-bottom:10px;
                      padding: 50px;
                      height:auto;
                    }
                    
                    .objectives-container h1 {
                      font-family: "poppins";
                      letter-spacing: 1px;
                      text-transform: uppercase;
                      color:black;
                      margin-bottom: 10px;
                    }
                    
                    .objectives-container p {
                      text-align: center;
                      max-width: 600px;
                      margin: 20px auto;
                      font-size: 18px;
                    }
                    
                    .objectives-container .cardss {
                    
                      gap:20px;
                    
                    }
                    
                    .objectives-container  .card-content {
                      background-color: #f2f2f2;
                      width: 190px ;
                      height:auto;
                    }
                    
                    .objectives-container  .card-content img {
                      width: 150px;
                      height: 150px;
                    }
                    
                    .objectives-container  .card-content h2 {
                      margin-bottom: 10px;
                      font-weight: 500;
                      font-size: 18px;
                  
                    }
            .contact{
              background: #fff;
              width: 200px;
              height:auto;
              padding: 10px;
              font-weight:400;
              transition: .3s all ease-in-out;
              margin-left:20px;
      
              
              }
              .linked--contacts{
                background: rgba(10, 45, 97, 0.829);
                  margin:auto;
                  color:#fff;
                }
                .linked--contacts>a{
                  color:#fff;
                
                
                }
            .counter{
              z-index:10;
              width: 100vw;
              height: 60vh;
              margin-top:300px;
              font-size:25px;
              flex-direction: column;
              padding:20px;
              }
              .track{
                flex-direction:row-reverse;
                justify-content: center;
              }
          .track>h3{
          font-family:"poppins";
          text-transform: capitalize;
          display: flex;
          font-size: 35px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-weight:600;
          gap:5px
          
          }
          .track>p{
            font-size:22.5px;
            font-weight:400;
            font-family: "poppins",sans-serif;
          
          }
          .targetss{
            
            display: flex;
            gap:20px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            }
            
            .tar{
            color:rgb(14, 14, 14);
            text-align: center;
            font-weight:400;
            font-size:16px;
            align-self: center;
            white-space: pre-line;
            border-radius: 5px;
            width:250px;
            height:80px;
            }
            .targets-sub{
            display: flex;
            gap:10px;
            align-items: center;
            justify-content:center;
            }
          .targeted_customers{
            height:270vh;
          
          }
          .services{
          height: 58vh;
          color:black;
          display:flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          }
          .ser{
            background: url("../assets/blob-scene-haikei.svg");
            background-repeat:no-repeat;
            background-size: cover;
            width: 100%;
            padding: 10px;
            height:300vh;
            position: relative;
            
          
          }
          .ser>.desc{
            height:200px;
            color:#fff;
            width:90%;
            margin:auto; 
            font-size: 22px;
          }
          .hoza{
            
              font-weight: 400;
              font-size: 16px;
              text-transform: capitalize;
              
          }
          .ex{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:30px;
            width: 100%;
            height:90vh;
            flex-direction:column;
            }
            .ex>*{
            background: #ffffff77;
            color:rgb(27, 27, 27);
            width: 250px;
            height: 300px;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius:5px;
            
            display:flex;
            align-items: center;
            justify-content:center;
            transition: .3s all ease-in-out;
            padding:10px;
            
            }
            
            
            .ex>div>p{
              font-size: 18px;
              font-weight: 400;
              font-family: "roboto";
              text-align: center;
              margin: auto;
              text-transform: capitalize;
              transition: .3s all ease-in-out;
            
            }
          .about_ceo{
            width: 100vw;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex:1;
            background: transparent;
            margin-bottom:80px;
            }
                .text{
                  width: 5100px;
                height:10%;
      }
     
        
            .objec{
              display: grid;
              grid-template-columns: repeat(1,410px);
             grid-template-rows: repeat(1,86px);
             grid-column-gap: 5px;
             grid-row-gap: 40px;
             margin-top: 30px;
             }
             .ceo{
            display:none;}
           
              .one{
               width:100vw;
                  margin:auto;
              text-align: left;
              font-size:17.5px;
              line-height: 2.5rem;
              font-family: 'Montserrat', sans-serif;
              margin-left:20px;
              margin-top:-25px;
              padding:10px;

              }

            
              .s{
flex-direction: column;

}
.s>*{
  width:300px
}
.int{
              text-align: left;
              font-size: 14px;
              text-transform: capitalize;
              font-weight: 500;
              }
              .hey>h2{
                text-align: left;
                text-transform: capitalize;
                font-size: 35px;
  margin-left:20px;

              }
             
              .targets-sub{
                display: flex;
                gap:10px;
                align-items: center;
                justify-content:center;
                flex-direction:column;
                }
                .hoza{
                font-size:15px;
                line-height: 3;
                font-weight: 500;
                margin-top:20px;
              
              }
              section{
                height:100vh;
              }

              .text,.about_ceo,.about-container{
                width:100vw;
              }

          }
</style>
